import cprExtract from "danish-ssn";
import { Controller, useFormContext } from "react-hook-form";
import { Fragment } from "react/jsx-runtime";
import { LargeButton } from "../components/button";
import { ButtonsWrapper } from "../components/buttons-wrapper";
import { Checkbox } from "../components/checkbox";
import { Input } from "../components/input";
import { Stepper } from "../components/stepper";
import { StepDescription, StepTitle } from "../components/text";
import { FormFields, StepProps } from "../types";

const TERMS_URL = "https://www.hemihealth.com/dk/terms-of-use";
const NEWSLETTER_TERMS_URL = "https://www.hemihealth.com/dk/privacy-policy#newsletter";

export const StepPersonal = ({ productConfig, onBackStep, onNextStep }: StepProps) => {
  const { control, clearErrors, trigger } = useFormContext<FormFields>();

  const handleContinue = async () => {
    clearErrors();

    const valid = await trigger(["cpr", "firstName", "lastName", "email", "acceptTerms"]);
    if (!valid) return;

    onNextStep();
  };

  return (
    <div>
      <Stepper products={productConfig.products} />

      <StepTitle>Indtast dine kontaktoplysninger</StepTitle>
      <StepDescription>
        Dine oplysninger bliver behandlet fortroligt og opbevares sikkert.
      </StepDescription>

      <div className="flex flex-col gap-3">
        <Controller
          name="cpr"
          control={control}
          rules={{
            required: "Indtast venligst dit CPR-nummer. 10 cifre uden bindestreg.",
            minLength: {
              value: 10,
              message: "CPR-nummeret skal være 10 cifre uden bindestreg.",
            },
            maxLength: {
              value: 10,
              message: "CPR-nummeret skal være 10 cifre uden bindestreg.",
            },
            validate: (value) => {
              try {
                const cpr = cprExtract(value);
                if (!cpr.valid) {
                  return "Indtast venligst et gyldigt CPR-nummer.";
                }
              } catch (error) {
                return "Indtast venligst et gyldigt CPR-nummer.";
              }
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              label="CPR-nummer"
              error={fieldState.error?.message}
              inputProps={{
                ...field,
                autoFocus: true,
              }}
            />
          )}
        />

        <div className="grid md:grid-cols-2 gap-3">
          <Controller
            name="firstName"
            control={control}
            rules={{ required: "Indtast venligst dit fornavn." }}
            render={({ field, fieldState }) => (
              <Input
                label="Fornavn"
                error={fieldState.error?.message}
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: "Indtast venligst dit efternavn." }}
            render={({ field, fieldState }) => (
              <Input
                label="Efternavn"
                error={fieldState.error?.message}
                inputProps={{
                  ...field,
                }}
              />
            )}
          />
        </div>

        <Controller
          name="email"
          control={control}
          rules={{
            required: "Indtast venligst din email adresse.",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Indtast venligst en gyldig email adresse.",
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              label="Email adresse"
              error={fieldState.error?.message}
              inputProps={{
                ...field,
                type: "email",
              }}
            />
          )}
        />
      </div>

      <div className="flex flex-col gap-y-2 mt-6 px-2">
        <Controller
          name="acceptTerms"
          control={control}
          rules={{
            required: "Du skal acceptere betingelserne for at fortsætte.",
          }}
          render={({ field, fieldState }) => (
            <Checkbox
              label={
                <Fragment>
                  Jeg har læst og accepterer{" "}
                  <a href={TERMS_URL} target="_blank" tabIndex={-1}>
                    betingelserne
                  </a>
                  .
                </Fragment>
              }
              inputProps={{
                ...field,
              }}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="acceptNewsletter"
          control={control}
          render={({ field, fieldState }) => (
            <Checkbox
              label={
                <Fragment>
                  <p className="mb-1">Jeg vil gerne modtage Hemi&rsquo;s nyhedsbrev.</p>
                  <p className="font-normal leading-snug text-[13px]">
                    Jeg har læst og forstået{" "}
                    <a href={NEWSLETTER_TERMS_URL} target="_blank" tabIndex={-1}>
                      betingelserne
                    </a>{" "}
                    for tilmelding til Hemi&rsquo;s nyhedsbrev og accepterer hermed at modtage
                    e-mails i overensstemmelse med ovenstående betingelser.
                  </p>
                </Fragment>
              }
              inputProps={{
                ...field,
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </div>

      <div className="mt-10 text-center">
        <ButtonsWrapper>
          <LargeButton variant="outline" onClick={onBackStep} tabIndex={-1}>
            Tilbage
          </LargeButton>

          <LargeButton onClick={handleContinue}>Fortsæt</LargeButton>
        </ButtonsWrapper>
      </div>
    </div>
  );
};
