import { FormFields } from "../types";

export const formDefaults: FormFields = {
  productKey: null,
  productVariant: null,
  date: null,
  time: null,
  cpr: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: {
    countryCode: "+45",
    number: "",
  },
  otp: "",
  acceptNewsletter: false,
  acceptTerms: false,
  booking: null,
};
