import * as Sentry from "@sentry/browser";
import { useFormContext } from "react-hook-form";
import { FormFields } from "../types";

export const ErrorBoundaryFallback = () => {
  Sentry.captureEvent({
    message: "ErrorBoundaryFallback",
  });

  return (
    <div className="pt-10 px-2 pb-10 max-w-lg mx-auto">
      <ErrorContainer>
        <UnknownErrorText />
      </ErrorContainer>
    </div>
  );
};

export const ApiErrorMessage = () => {
  const { formState } = useFormContext<FormFields>();
  const { errors } = formState;

  Sentry.captureEvent({
    message: "ApiErrorMessage",
    extra: errors,
  });

  // If there are no knowm api errors fallback
  // to the default error boundary
  if (errors.root?.type !== "api-known") {
    return (
      <ErrorContainer>
        <UnknownErrorText />
      </ErrorContainer>
    );
  }

  return (
    <ErrorContainer>
      {Object.values(errors).map((error, index) => (
        <p key={index}>{error.message}</p>
      ))}
      <p>Klik på "Tilbage" knappen for at rette fejlen.</p>
    </ErrorContainer>
  );
};

const UnknownErrorText = () => (
  <>
    <p>Der er desværre opstået en fejl under bookingen.</p>
    <p>
      Kontakte os gerne på tlf:{" "}
      <a className="underline" href="tel:42542200">
        42 54 22 00
      </a>
    </p>
    <p>Så hjælper vi dig med at booke en tid.</p>
  </>
);

const ErrorContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="rounded-md bg-yellow-50 p-4 text-center">
      <h3 className="text-sm font-bold mb-2">Der opstod en fejl</h3>
      <div className="flex flex-col gap-y-2 text-sm">{children}</div>
    </div>
  );
};
