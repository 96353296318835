import { format } from "date-fns";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { AnimatedCheckIcon, ExternalLinkIcon } from "../components/icons";
import { Price } from "../components/price";
import { StepTitle } from "../components/text";
import { getEnvVar, getProduct } from "../lib/utils";
import { FormFields, ProductConfig } from "../types";

declare const window: Window & { dataLayer: Record<string, unknown>[] };

type Props = {
  productConfig: ProductConfig;
};

export const StepCompleted = ({ productConfig }: Props) => {
  const { getValues } = useFormContext<FormFields>();
  const product = getProduct(productConfig, getValues("productKey"))!;
  const productVariant = getValues("productVariant")!;
  const booking = getValues("booking");

  useEffect(() => {
    if (getEnvVar("GTM_ID")) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "purchase",
        currency: "DKK",
        value: productVariant.price,
        transaction_id: `T_${booking?.timestamp}`,
        ecommerce: {
          items: [
            {
              item_id: productVariant.id,
              item_name: product.name,
              location: productVariant.location.value,
              membership: productVariant.membership?.includes("yes") ? "yes" : "no",
              price: productVariant.price,
              quantity: 1,
            },
          ],
          customer: {
            firstName: getValues("firstName"),
            lastName: getValues("lastName"),
            email: getValues("email"),
          },
        },
      });
    }
  }, []);

  return (
    <div className="flex flex-col items-center">
      <div className="bg-primary-800 h-16 w-full flex justify-center rounded-t-4xl">
        {booking?.hcpPicture && (
          <img
            src={booking.hcpPicture}
            alt={booking.hcpName ?? ""}
            className="h-28 w-auto rounded-full transform -translate-y-6 border-4 border-white bg-gray-25"
          />
        )}
      </div>
      <div className="bg-gray-25 rounded-b-4xl pt-10 pb-8 px-2 flex flex-col gap-y-2 items-center w-full">
        <StepTitle>
          <div className="flex flex-row gap-x-2 items-center -translate-x-3">
            <AnimatedCheckIcon />
            Booking bekræftet
          </div>
        </StepTitle>

        <div className="flex flex-col gap-y-4 text-center">
          {booking?.hcpName && <p>Din tid er nu booket med {booking.hcpName}.</p>}

          <div className="flex flex-col gap-y-5">
            <Price
              product={product}
              productVariant={productVariant}
              membership={productConfig.membership}
            />

            <div>
              {booking?.startAt && (
                <p>
                  <strong className="font-semibold">Tidspunkt</strong>:{" "}
                  {format(booking.startAt, "d MMM yyyy 'kl' HH:mm")} -{" "}
                  {format(booking.endAt, "HH:mm")}
                </p>
              )}

              {booking?.location && (
                <p>
                  <strong className="font-semibold">Sted</strong>:{" "}
                  <a
                    href={booking.link}
                    target="_blank"
                    className="inline-flex gap-x-1 items-center underline underline-offset-2 hover:no-underline"
                  >
                    {booking.location}
                    <ExternalLinkIcon />
                  </a>
                </p>
              )}
            </div>
          </div>

          <p>Du modtager en bekræftelse med din booking på email.</p>
        </div>
      </div>
    </div>
  );
};
