import clsx from "clsx";

export const RadioButtonList = ({ children }: { children: React.ReactNode }) => (
  <div className="grid gap-y-3 mt-4">{children}</div>
);

type RadioButtonProps = {
  groupName: string;
  isSelected: boolean;
  value: string | number;
  onClick: () => void;
  children: React.ReactNode;
};

export const RadioButton = (props: RadioButtonProps) => (
  <label
    className={clsx(
      "flex items-start gap-x-2 cursor-pointer bg-white rounded-4xl px-7 py-3.5 relative transition-all",
      props.isSelected ? "ring ring-inset ring-primary-50" : ""
    )}
    htmlFor={`radio-${props.value}`}
  >
    <div className="flex flex-col">{props.children}</div>

    <input
      id={`radio-${props.value}`}
      type="radio"
      onChange={props.onClick}
      name={props.groupName}
      checked={props.isSelected}
      className="invisible"
    />

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={clsx(
        "size-6 absolute top-4 right-5 text-primary-600 transition-opacity duration-500",
        props.isSelected ? "opacity-100" : "opacity-0"
      )}
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
        clipRule="evenodd"
      />
    </svg>
  </label>
);
