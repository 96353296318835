import { format } from "date-fns";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { LargeButton } from "../components/button";
import { ButtonsWrapper } from "../components/buttons-wrapper";
import { ApiErrorMessage } from "../components/errors";
import { Price } from "../components/price";
import { Spinner } from "../components/spinner";
import { Stepper } from "../components/stepper";
import { StepTitle } from "../components/text";
import { book, signup } from "../lib/api";
import { useHandleApiError } from "../lib/hooks";
import { getProduct } from "../lib/utils";
import { Booking, FormFields, StepProps } from "../types/";

type Props = StepProps & {
  token?: string;
};

export const StepConfirm = ({ productConfig, onBackStep, onNextStep, token }: Props) => {
  const { setValue, clearErrors, getValues, formState } = useFormContext<FormFields>();
  const product = getProduct(productConfig, getValues("productKey"));
  const productVariant = getValues("productVariant");
  const { getToken } = useGetToken();
  const [isLoading, setIsLoading] = useState(false);
  const { handleSignupError, handleBookError } = useHandleApiError();
  const time = getValues("time");

  const handleSubmit = async () => {
    clearErrors();
    setIsLoading(true);

    if (!token) {
      try {
        const signupResponse = await signup({
          cpr: getValues("cpr"),
          otp: getValues("otp"),
          firstName: getValues("firstName"),
          lastName: getValues("lastName"),
          email: getValues("email"),
          phone: getValues("phone"),
        });

        if (!signupResponse.ok) {
          await handleSignupError(signupResponse);
          setIsLoading(false);
          return;
        }

        token = await getToken(signupResponse);
        if (!token) {
          setIsLoading(false);
          return;
        }
      } catch (error) {
        console.error(error);
        handleSignupError();
        setIsLoading(false);
        return;
      }
    }
    try {
      const time = getValues("time");
      const bookResponse = await book(token, {
        cpr: getValues("cpr"),
        productId: productVariant?.id as number,
        calendarId: time?.calendarId as number,
        employeeId: time?.employeeId as number,
        start: time?.start?.toISOString() as string,
        end: time?.end?.toISOString() as string,
        acceptNewsletter: getValues("acceptNewsletter"),
      });

      if (!bookResponse.ok) {
        await handleBookError(bookResponse);
        setIsLoading(false);
        return;
      }

      const booking: Booking = await bookResponse.json();
      setValue("booking", { ...booking, timestamp: new Date().getTime() });
      onNextStep();
    } catch (error) {
      console.error(error);
      handleBookError();
      setIsLoading(false);
      return;
    }
  };

  const handleGoBack = () => {
    clearErrors();
    setValue("otp", "");
    onBackStep();
  };

  if (!product || !productVariant) return null;

  return (
    <div role="main">
      <Stepper products={productConfig.products} />

      <div className="bg-gray-25 rounded-4xl py-8 px-2">
        <StepTitle>Bekræft din booking</StepTitle>

        <div className="flex flex-col gap-y-5 text-center mt-4 sm:px-8">
          <Price
            product={product}
            productVariant={productVariant}
            membership={productConfig.membership}
          />

          <div className="leading-normal">
            {time?.start && time.end && (
              <p>
                <strong className="font-semibold">Tidspunkt</strong>:{" "}
                {format(time.start, "d MMM yyyy 'kl' HH:mm")} - {format(time.end, "HH:mm")}
              </p>
            )}

            <p>
              <strong className="font-semibold">Sted</strong>: {productVariant.location.title}
            </p>
          </div>
        </div>
      </div>

      {Object.keys(formState.errors).length > 0 && (
        <div className="mt-10">
          <ApiErrorMessage />
        </div>
      )}

      <ButtonsWrapper>
        <LargeButton disabled={isLoading} variant="outline" onClick={handleGoBack} tabIndex={-1}>
          Tilbage
        </LargeButton>
        <LargeButton disabled={isLoading} onClick={handleSubmit}>
          {isLoading ? <Spinner color="light" size="sm" /> : "Bekræft booking"}
        </LargeButton>
      </ButtonsWrapper>
    </div>
  );
};

const useGetToken = () => {
  const { setError } = useFormContext<FormFields>();

  const getToken = async (response: Response) => {
    try {
      const json = await response.json();
      return json.token;
    } catch (error) {
      setError("root", {
        type: "api",
        message: "Der er sket en fejl. Prøv igen senere.",
      });
      console.log("No token");
      return null;
    }
  };

  return { getToken };
};
