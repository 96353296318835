import { useReducer } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { formDefaults } from "./lib/form";
import { getProduct } from "./lib/utils";
import { stepReducer } from "./step-reducer";
import { StepCompleted } from "./steps/step-completed";
import { StepConfirm } from "./steps/step-confirm";
import { StepDates } from "./steps/step-dates";
import { StepOtp } from "./steps/step-otp";
import { StepPersonal } from "./steps/step-personal";
import { StepPhone } from "./steps/step-phone";
import { StepProduct } from "./steps/step-product";
import { FormFields, ProductConfig } from "./types";

type Props = {
  productConfig: ProductConfig;
  productKey?: string;
  token?: string;
};

export const Form = (props: Props) => {
  const product = getProduct(props.productConfig, props.productKey);
  const isSingleVariant = product?.variants.length === 1;

  const [state, dispatch] = useReducer(stepReducer, {
    mode: props.token ? "user" : "guest",
    step: isSingleVariant ? "dates" : "products",
  });

  const handleGoBack = () => dispatch({ type: "GO_BACK" });
  const handleNextStep = () => dispatch({ type: "GO_NEXT" });

  const form = useForm<FormFields>({
    defaultValues: {
      ...formDefaults,
      productKey: product ? product.key : null,
      productVariant: isSingleVariant ? product?.variants[0] : null,
    },
    shouldFocusError: true,
    mode: "onBlur",
  });

  if (!product) {
    return (
      <Container>
        <p className="text-center">Vi kunne desværre ikke finde den ønskede ydelse.</p>
      </Container>
    );
  }

  return (
    <FormProvider {...form}>
      <Container>
        {/* <pre className="text-[11px] h-64 border overflow-auto">
          {JSON.stringify(watch(), null, 2)}
        </pre>*/}

        {state.step === "products" && <StepProduct onNextStep={handleNextStep} {...props} />}

        {state.step === "dates" && (
          <StepDates onBackStep={handleGoBack} onNextStep={handleNextStep} {...props} />
        )}
        {state.step === "personal" && (
          <StepPersonal onBackStep={handleGoBack} onNextStep={handleNextStep} {...props} />
        )}
        {state.step === "phone" && (
          <StepPhone onBackStep={handleGoBack} onNextStep={handleNextStep} {...props} />
        )}
        {state.step === "otp" && (
          <StepOtp onBackStep={handleGoBack} onNextStep={handleNextStep} {...props} />
        )}
        {state.step === "confirm" && (
          <StepConfirm onBackStep={handleGoBack} onNextStep={handleNextStep} {...props} />
        )}
        {state.step === "completed" && <StepCompleted {...props} />}
      </Container>
    </FormProvider>
  );
};

const Container = ({ children }: { children: React.ReactNode }) => (
  <div className="max-w-lg mx-auto my-6 max-sm:px-2">{children}</div>
);
