import clsx from "clsx";

export const StepTitle = ({
  className = "",
  size = "lg",
  children,
}: {
  className?: string;
  size?: "lg" | "md";
  children: React.ReactNode;
}) => (
  <h2
    className={clsx(
      "text-center mb-2",
      size === "md" ? "text-primary-800" : "",
      size === "lg" ? "font-medium text-xl text-primary-800" : "",
      className
    )}
  >
    {children}
  </h2>
);

export const StepDescription = ({ children }: { children: React.ReactNode }) => (
  <p className="text-center text-[13px] text-gray-900 mb-4">{children}</p>
);
