import { Spinner } from "./components/spinner";
import { Form } from "./form";
import { useGetProducts } from "./lib/api";

type Props = {
  productKey?: string;
  token?: string;
};

function App(props: Props) {
  const { data, isLoading } = useGetProducts();

  if (isLoading || !data) {
    return (
      <div className="flex justify-center my-20">
        <Spinner size="lg" color="dark" />
      </div>
    );
  }

  return <Form productConfig={data} token={props.token} productKey={props.productKey} />;
}

export default App;
