import clsx from "clsx";

type InputProps = {
  label?: string;
  error: string | undefined;
  inputProps: React.ComponentProps<"input">;
};

export const Input = (props: InputProps) => {
  return (
    <div className="w-full">
      {props.label && (
        <label htmlFor={props.inputProps.name} className="block leading-6 text-gray-900 px-1">
          {props.label}
        </label>
      )}

      <input
        className={clsx(
          "block w-full rounded-lg mt-0.5 px-3 py-2 transition-all",
          "border-transparent outline outline-1 outline-gray-300 focus:outline-1 focus:outline-primary-300 focus:ring-4 focus:ring-primary-100",
          props.error && "ring-2 ring-red-500 outline-transparent"
        )}
        {...props.inputProps}
      />

      {props.error && <p className="mt-2 px-1 text-xs text-red-600">{props.error}</p>}
    </div>
  );
};
