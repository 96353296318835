import { useFormContext } from "react-hook-form";
import { LargeButton } from "../components/button";
import { ButtonsWrapper } from "../components/buttons-wrapper";
import { InputOTP } from "../components/input-otp";
import { Stepper } from "../components/stepper";
import { StepDescription, StepTitle } from "../components/text";
import { getProduct } from "../lib/utils";
import { FormFields, StepProps } from "../types";

export const StepOtp = ({ productConfig, onBackStep, onNextStep }: StepProps) => {
  const { getValues, setValue, trigger } = useFormContext<FormFields>();
  const product = getProduct(productConfig, getValues("productKey"));
  const phone = getValues("phone");

  const handleGoBack = () => {
    setValue("otp", "");
    onBackStep();
  };

  const handleContinue = async () => {
    const valid = await trigger("otp");
    if (!valid) return;

    onNextStep();
  };

  if (!product) return null;

  return (
    <div>
      <Stepper products={productConfig.products} />

      <StepTitle>Indtast SMS kode</StepTitle>
      <StepDescription>
        Hvis du ikke har modtaget koden efter 30 sekunder eller hvis du har tastet et forkert
        mobilnummer ({phone.countryCode} {phone.number}), så{" "}
        <button
          className="underline hover:no-underline"
          onClick={handleGoBack}
          type="button"
          tabIndex={-1}
        >
          klik her for at prøve igen
        </button>
        .
      </StepDescription>

      <div className="mb-14">
        <InputOTP />
      </div>

      <ButtonsWrapper>
        <LargeButton variant="outline" onClick={handleGoBack} tabIndex={-1}>
          Tilbage
        </LargeButton>
        <LargeButton onClick={handleContinue}>Fortsæt</LargeButton>
      </ButtonsWrapper>
    </div>
  );
};
