import clsx from "clsx";

type SelectProps = {
  error: string | undefined;
  selectProps: React.ComponentProps<"select">;
  children: React.ReactNode;
};

export const Select = (props: SelectProps) => {
  return (
    <select
      className={clsx(
        "block w-full rounded-lg mt-0.5 px-2 border-transparent border-r-8 py-2 transition-all",
        "shadow-sm outline outline-1 outline-gray-300 focus:outline-1 focus:outline-primary-300 focus:ring-4 focus:ring-primary-100",
        "appearance-none bg-white text-right",
        props.error && "ring-2 ring-red-500"
      )}
      {...props.selectProps}
    >
      {props.children}
    </select>
  );
};
