type State = {
  mode: "guest" | "user";
  step: GuestSteps | UserSteps;
};

type Action = { type: "GO_BACK" } | { type: "GO_NEXT" };
type GuestSteps = (typeof steps.guest)[number];
type UserSteps = (typeof steps.user)[number];

const steps = {
  guest: ["products", "dates", "personal", "phone", "otp", "confirm", "completed"] as const,
  user: ["dates", "confirm", "completed"] as const,
};

export const stepReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "GO_BACK": {
      const index =
        state.mode === "guest"
          ? steps[state.mode].indexOf(state.step as GuestSteps)
          : steps[state.mode].indexOf(state.step as UserSteps);

      return {
        ...state,
        step: steps[state.mode][index - 1],
      };
    }
    case "GO_NEXT": {
      const index =
        state.mode === "guest"
          ? steps[state.mode].indexOf(state.step as GuestSteps)
          : steps[state.mode].indexOf(state.step as UserSteps);

      return {
        ...state,
        step: steps[state.mode][index + 1],
      };
    }
    default:
      return state;
  }
};
