import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FormFields } from "../types";

export const useHandleApiError = () => {
  const { setError } = useFormContext<FormFields>();

  const handleSignupError = async (response?: Response) => {
    try {
      const json = await response?.json();
      if (json.field) {
        const message =
          json.field === "cpr"
            ? "Det indtastede CPR nummer er ugyldigt."
            : json.field === "otp"
            ? "Den indtastede SMS kode er forkert."
            : json.message;

        setError("root", {
          type: "api-known",
          message,
        });
        return;
      }

      setError("root", {
        type: "api-unknown",
      });
    } catch (error) {
      setError("root", {
        type: "api-unknown",
      });
    }
  };

  const handleBookError = async (response?: Response) => {
    try {
      const json = await response?.json();
      if (json.field) {
        setError("root", { type: "api-known", message: json.message });
        return;
      }
      setError("root", {
        type: "api-unknown",
      });
    } catch (error) {
      setError("root", {
        type: "api-unknown",
      });
    }
  };

  return { handleSignupError, handleBookError };
};

export const useEffectOnNextTick = (cb: () => void) => {
  useEffect(() => {
    const id = setTimeout(() => {
      cb();
    });

    return () => clearTimeout(id);
  }, []);
};
