type CheckboxProps = {
  label: React.ReactElement;
  error: string | undefined;
  inputProps: Omit<React.ComponentPropsWithoutRef<"input">, "value"> & {
    value: boolean;
  };
};

export const Checkbox = (props: CheckboxProps) => {
  const { value, ...inputProps } = props.inputProps;

  return (
    <div className="flex items-start">
      <div className="flex h-6 items-center">
        <input
          {...inputProps}
          checked={value}
          id={props.inputProps.name}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 ml-0.5"
        />
      </div>
      <div className="ml-2 text-sm leading-6">
        <label htmlFor={props.inputProps.name} className="font-medium [&_a]:underline">
          {props.label}
        </label>

        {props.error && <p className="text-xs text-red-600">{props.error}</p>}
      </div>
    </div>
  );
};
