import { clsx } from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import OtpInput from "react-otp-input";
import { FormFields } from "../types";

export const OTP_LENGTH = 6;

export const InputOTP = () => {
  const { control } = useFormContext<FormFields>();

  return (
    <Controller
      name="otp"
      control={control}
      rules={{
        required: "Indtast venligst koden fra SMS.",
        minLength: {
          value: 6,
          message: "SMS koden skal være på 6 cifre.",
        },
        maxLength: {
          value: 6,
          message: "SMS koden skal være på 6 cifre.",
        },
      }}
      render={({ field, fieldState }) => {
        const { ref, ...fieldProps } = field;
        const hasError = !!fieldState.error;

        return (
          <>
            <OtpInput
              {...fieldProps}
              skipDefaultStyles
              containerStyle={clsx(
                "flex flex-no-wrap justify-evenly gap-x-px w-full bg-gray-400 p-px rounded-md overflow-hidden",
                {
                  "bg-red-500": hasError,
                }
              )}
              numInputs={OTP_LENGTH}
              inputType="tel"
              renderInput={(props, index) => (
                <input
                  {...props}
                  autoFocus={index === 0}
                  tabIndex={index + 1}
                  className={clsx(
                    "bg-white flex-1 w-full text-center h-14",
                    "transition-all duration-300",
                    "rounded-none border-none outline-none first:rounded-l-md last:rounded-r-md",
                    "ring-primary-50 ring-inset focus:ring-2"
                  )}
                />
              )}
            />

            {hasError && fieldState.error && (
              <p className="text-center text-xs text-red-600 mt-2">{fieldState.error.message}</p>
            )}
          </>
        );
      }}
    />
  );
};
