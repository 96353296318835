import { format } from "date-fns";
import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { FormFields, Product } from "../types";

type Props = {
  products: Product[];
};

export const Stepper = ({ products }: Props) => {
  const { getValues } = useFormContext<FormFields>();
  const time = getValues("time");
  const productGroup = products.find((p) => p.key === getValues("productKey")) || null;

  if (!productGroup) return null;

  return (
    <div className="grid grid-cols-2 gap-y-2 gap-x-2 sm:gap-x-5 mb-8">
      <Step title="Ydelse">{productGroup.shortName}</Step>
      <Step title="Tidspunkt">
        {time?.start && time?.end ? (
          <Fragment>
            {format(time.start, "d MMM 'kl' HH:mm")}-{format(time.end, "HH:mm")}
          </Fragment>
        ) : (
          "Ikke valgt"
        )}
      </Step>
    </div>
  );
};

type StepProps = {
  title: string;
  children: string | JSX.Element;
};
const Step = ({ title, children }: StepProps) => (
  <div className="flex flex-col bg-gray-25 py-1.5 px-2.5 rounded-xl relative group">
    <p className="text-xs text-gray-800">{title}</p>
    <p className="flex justify-between text-xs text-left">{children}</p>
  </div>
);
