import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { LargeButton } from "../components/button";
import { ClockIcon, MapIcon } from "../components/icons";
import { Price } from "../components/price";
import { RadioButton, RadioButtonList } from "../components/radio-button";
import { StepTitle } from "../components/text";
import { useEffectOnNextTick } from "../lib/hooks";
import { getProduct } from "../lib/utils";
import {
  FormFields,
  Product,
  ProductLocationOption,
  ProductMembershipOption,
  StepProps,
} from "../types/";

type Props = Omit<StepProps, "onBackStep">;

export const StepProduct = ({ productConfig, onNextStep }: Props) => {
  const { setValue, getValues, trigger, watch } = useFormContext<FormFields>();
  const productVariant = watch("productVariant");
  const product = getProduct(productConfig, getValues("productKey"));
  const firstLocation = product?.locations?.[0] ?? null;
  const firstMembership = product?.memberships?.[0] ?? null;
  const [currentLocation, setCurrentLocation] = useState<ProductLocationOption | null>(
    firstLocation
  );
  const [currentMembership, setCurrentMembership] = useState<ProductMembershipOption | null>(
    firstMembership
  );

  // Select product on next tick to make react-hook-form happy
  useEffectOnNextTick(() => {
    handleSelectProduct(currentLocation, currentMembership);
  });

  const handleLocationChange = (location: ProductLocationOption) => {
    setCurrentLocation(location);
    handleSelectProduct(location, currentMembership);
  };

  const handleMembershipChange = (membership: ProductMembershipOption) => {
    setCurrentMembership(membership);
    handleSelectProduct(currentLocation, membership);
  };

  const handleContinue = async () => {
    const valid = await trigger(["productVariant"]);
    if (!valid) return;

    setValue("date", null);
    setValue("time", null);
    onNextStep();
  };

  const handleSelectProduct = (
    location: ProductLocationOption | null,
    membership: ProductMembershipOption | null
  ) => {
    if (!product || !location || !membership) return;

    const variant = getVariant(product, location, membership);

    setValue("productVariant", variant);
    setValue("date", null);
    setValue("time", null);
  };

  if (
    product === null ||
    product.locations?.length === 0 ||
    product.memberships?.length === 0 ||
    product.variants.length === 0
  ) {
    return (
      <p role="alert" className="text-center">
        Der er desværre ingen ledige ydelser.
      </p>
    );
  }

  return (
    <div role="main">
      <StepTitle size="md" className="mt-5">
        Hvor ønsker du at konsultationen skal foregå?
      </StepTitle>

      <RadioButtonList>
        {product.locations?.map((location) => (
          <RadioButton
            groupName="location"
            isSelected={location.value === currentLocation?.value}
            onClick={() => handleLocationChange(location)}
            value={location.value}
            key={location.value}
          >
            <h4 role="heading" className="font-semibold">
              {location.title}
            </h4>

            <div className="flex flex-col sm:flex-row sm:items-center sm:gap-x-3">
              <div className="flex items-center gap-x-1">
                <ClockIcon />
                <p>{location.duration} minutter</p>
              </div>
              <div className="flex items-center gap-x-1">
                <MapIcon />
                <p>{location.description}</p>
              </div>
            </div>
          </RadioButton>
        ))}
      </RadioButtonList>

      <StepTitle size="md" className="mt-10">
        Ønsker du et Hemi Medlemskab?
      </StepTitle>

      <RadioButtonList>
        {product.memberships?.map((membership) => (
          <RadioButton
            groupName="membership"
            isSelected={membership.value === currentMembership?.value}
            onClick={() => handleMembershipChange(membership)}
            value={membership.value}
            key={membership.value}
          >
            <h4 role="heading" className="font-semibold">
              {membership.title}
            </h4>

            {membership.description && (
              <p className="text-sm whitespace-pre mb-2 sm:mb-1">{membership.description}</p>
            )}
          </RadioButton>
        ))}
      </RadioButtonList>

      {productVariant && (
        <div className="mt-10">
          <StepTitle size="md">Din booking</StepTitle>

          <div className="bg-gray-25 rounded-4xl p-8 text-center mt-4">
            <Price
              product={product}
              productVariant={productVariant}
              membership={productConfig.membership}
            />
          </div>
        </div>
      )}

      <div className="mt-10 text-center">
        <LargeButton onClick={handleContinue}>Vis ledige tider</LargeButton>
      </div>
    </div>
  );
};

const getVariant = (
  product: Product,
  location: ProductLocationOption,
  membership: ProductMembershipOption
) =>
  product?.variants.find(
    (p) => p.location.value === location.value && p.membership?.includes(membership.value)
  ) || null;
