import { differenceInWeeks, format, isSameDay, isSameHour, isSameMinute, parseISO } from "date-fns";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LargeButton, SelectButton } from "../components/button";
import { ButtonsWrapper } from "../components/buttons-wrapper";
import { Spinner } from "../components/spinner";
import { Stepper } from "../components/stepper";
import { StepTitle } from "../components/text";
import { useGetAvailableDates, useGetAvailableTimes } from "../lib/api";
import { getProduct } from "../lib/utils";
import { AvailableDateResult, FormFields, StepProps } from "../types/";

const DEFAULT_DAYS_LIMIT = 10;
const DAYS_LIMIT_INCREMENT = 5;

export const StepDates = ({ productConfig, onBackStep, onNextStep }: StepProps) => {
  const { setValue, control, watch, trigger } = useFormContext<FormFields>();
  const { dates, isLoading, daysLimit, setDaysLimit, totalDates } = useDates();
  const selectedDate = watch("date")!;
  const productKey = watch("productKey")!;
  const product = getProduct(productConfig, productKey);
  const isMultiVariant = product?.variants.length !== 1;

  const handleGoBack = () => {
    onBackStep();
  };

  const handleContinue = async () => {
    const valid = await trigger(["date", "time"]);
    if (!valid) return;

    onNextStep();
  };

  const handleSelectDate = (date: Date) => {
    setValue("date", date);
    setValue("time", null);
  };

  const handleShowMoreDates = () => {
    setDaysLimit(daysLimit + DAYS_LIMIT_INCREMENT);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center my-20">
        <Spinner size="sm" color="dark" />
      </div>
    );
  }

  if (totalDates === 0) {
    return (
      <div className="text-center">
        <p className="my-5">Der er desværre ingen ledige tider til {product?.name}.</p>
        {isMultiVariant && <LargeButton onClick={handleGoBack}>Gå tilbage</LargeButton>}
      </div>
    );
  }

  return (
    <div role="main">
      <Stepper products={productConfig.products} />
      <StepTitle>Vælg en dato</StepTitle>

      <div className="grid grid-cols-5 gap-x-2 mb-1 mt-4">
        <div className="text-gray-800 text-center">Man</div>
        <div className="text-gray-800 text-center">Tirs</div>
        <div className="text-gray-800 text-center">Ons</div>
        <div className="text-gray-800 text-center">Tors</div>
        <div className="text-gray-800 text-center">Fre</div>
      </div>
      <div data-testid="dates-list" className="grid grid-cols-5 gap-x-2 gap-y-4">
        {dates
          .filter((_, i) => i < daysLimit)
          .map((date, j) => {
            const isSelected = isSameDay(date.start, selectedDate);

            return (
              <div className="flex justify-center" key={j}>
                <Controller
                  name="date"
                  control={control}
                  rules={{ required: true }}
                  render={({ fieldState }) => (
                    <SelectButton
                      disabled={!date.available}
                      error={!!fieldState.error}
                      selected={isSelected}
                      onClick={() => handleSelectDate(date.start)}
                    >
                      {format(date.start, "d MMM")}
                    </SelectButton>
                  )}
                />
              </div>
            );
          })}
      </div>

      <div className="flex justify-center mt-6 mb-12">
        {totalDates > daysLimit && (
          <button className="px-5 underline underline-offset-2" onClick={handleShowMoreDates}>
            Vis flere datoer
          </button>
        )}
      </div>

      <StepTimes />

      <ButtonsWrapper>
        {isMultiVariant ? (
          <LargeButton variant="outline" onClick={handleGoBack} tabIndex={-1}>
            Tilbage
          </LargeButton>
        ) : (
          <div></div>
        )}
        <LargeButton onClick={handleContinue}>Fortsæt</LargeButton>
      </ButtonsWrapper>
    </div>
  );
};

const StepTimes = () => {
  const { watch, setValue, control } = useFormContext<FormFields>();
  const productVariant = watch("productVariant")!;
  const date = watch("date");
  const { times, isLoading } = useGetAvailableTimes(productVariant, date);
  const selectedTime = watch("time");

  if (!date) return null;
  if (isLoading) {
    return (
      <div className="flex justify-center my-20">
        <Spinner size="sm" color="dark" />
      </div>
    );
  }
  if (times.length === 0) return <p>Der er desværre ingen ledige tider {format(date, "dd-MMM")}</p>;

  return (
    <div>
      <StepTitle>Vælg et tidspunkt</StepTitle>

      <div data-testid="times-list" className="grid grid-cols-5 gap-x-2 gap-y-4 mt-4">
        {times.map((time, j) => {
          const isSelected =
            selectedTime?.start != null &&
            isSameDay(time.start, selectedTime.start) &&
            isSameHour(time.start, selectedTime.start) &&
            isSameMinute(time.start, selectedTime.start);

          return (
            <div className="flex justify-center" key={j}>
              <Controller
                name="time"
                control={control}
                rules={{ required: true }}
                render={({ fieldState }) => (
                  <SelectButton
                    error={!!fieldState.error}
                    selected={isSelected}
                    onClick={() => setValue("time", time, { shouldValidate: true })}
                  >
                    {format(time.start, "HH:mm")}
                  </SelectButton>
                )}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Calculate the number of days to show based on the difference in weeks
// - If the first available date is in 2 weeks, show 10 days
// - If the first available date is in 3 weeks, show 15 days
const getDaysLimit = (date: string) => {
  const weeksDiff = differenceInWeeks(date, new Date());
  const newLimit = Math.max(DEFAULT_DAYS_LIMIT, (weeksDiff + 1) * DAYS_LIMIT_INCREMENT);
  return newLimit;
};

const useDates = () => {
  const [daysLimit, setDaysLimit] = useState(DEFAULT_DAYS_LIMIT);
  const { setValue, watch } = useFormContext<FormFields>();
  const productVariant = watch("productVariant")!;

  const { dates, isLoading } = useGetAvailableDates(productVariant, {
    onSuccess: (result: AvailableDateResult[] | { message: string }) => {
      // If there is an error message, do nothing
      if ("message" in result) return;

      // Set the first available date as the default date
      const firstAvailable = result.find((date) => date.available);
      if (firstAvailable) {
        const newDaysLimit = getDaysLimit(firstAvailable.start);
        setDaysLimit(newDaysLimit);
        setValue("date", parseISO(firstAvailable.start));
      }
    },
  });

  return {
    dates,
    isLoading,
    daysLimit,
    setDaysLimit,
    totalDates: Object.keys(dates).length,
  };
};
