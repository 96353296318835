import { currency } from "../lib/utils";
import { Membership, Product, ProductVariant } from "../types/";

type Props = {
  product: Product;
  productVariant: ProductVariant;
  membership: Membership;
};

export const Price = ({ product, productVariant, membership }: Props) => (
  <div className="leading-normal">
    <p>
      <strong className="font-semibold">{product.name}</strong>:{" "}
      <span className="whitespace-nowrap">{currency(productVariant.price ?? 0)}</span>
    </p>

    {productVariant.membership?.includes("yes") && (
      <>
        <p>
          <strong className="font-semibold">{membership.title}</strong>:{" "}
          <span className="whitespace-nowrap">
            {currency(membership.price)}
            {membership.priceAffix}
          </span>
          <small className="block text-xs">{membership.priceSubtext}</small>
        </p>
      </>
    )}
  </div>
);
