import { cx } from "../lib/utils";

type ButtonProps = React.ComponentPropsWithoutRef<"button"> & {
  variant?: "solid" | "outline";
};

export const Button = (props: ButtonProps) => (
  <button
    type="button"
    {...props}
    disabled={props.disabled}
    className={cx(
      {
        solid: "bg-primary-800 border border-primary-800 hover:bg-primary-700 text-white",
        outline:
          "border border-gray-500 bg-gray-100 text-gray-700 hover:bg-gray-50 hover:text-gray-800",
      }[props.variant || "solid"],
      "rounded-2xl px-2 py-1 transition-all duration-300",
      "focus:ring-4 focus:ring-offset-0 focus:ring-primary-100",
      props.disabled ? "!bg-gray-100 !text-gray-800 !cursor-not-allowed" : "",
      props.className ? props.className : ""
    )}
  >
    {props.children}
  </button>
);

export const LargeButton = (props: ButtonProps) => {
  const { disabled, className, ...rest } = props;

  return (
    <Button
      {...rest}
      className={cx(
        "!px-6 !py-2.5 !font-semibold",
        disabled ? "pointer-events-none" : "",
        className ? className : ""
      )}
    />
  );
};

type SelectButtonProps = ButtonProps & {
  selected: boolean;
  error?: boolean;
};

export const SelectButton = (props: SelectButtonProps) => {
  const { selected, error, ...rest } = props;

  return (
    <button
      {...rest}
      data-testid="select-button"
      type="button"
      className={cx(
        "rounded-full h-9 w-full max-w-20 text-sm",
        "hover:bg-primary-50 text-primary-800",
        selected ? "ring ring-primary-300 bg-primary-50" : "bg-white",
        error ? "ring-2 ring-red-400" : "",
        props.disabled ? " !bg-gray-100 !text-gray-500 !cursor-not-allowed" : ""
      )}
    />
  );
};
